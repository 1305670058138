<template>
  <GreenBlock class-name="green-section--big mb-0">
    <v-container>
      <h1 class="page-title max-width mb-9 mt-9">
        Vielen Dank für Ihr Interesse an unseren innovativen strompaketen.
      </h1>
      <div class="green-section__content mb-5">
        <div class="green-section--left">
          <div class="page-subtitle mb-6">
            Das individuelle Angebot, das Sie aufrufen wollten, ist leider nicht
            mehr gültig. Bitte schreiben Sie uns eine E-Mail an
            <a
              href="mailto:strompakete@eprimo.de"
              style="text-decoration: underline"
              >strompakete@eprimo.de</a
            >
            und wir senden Ihnen gerne ein neues persönliches Angebot zu.
          </div>
          <div class="page-subtitle mb-6">
            Sie können uns bei Fragen auch gerne telefonisch erreichen.
            <br />Die Rufnummer lautet:
          </div>
          <div class="page-subtitle mb-12 page-subtitle-mobile-expired">
            069 / 697670444
            <div class="green-face">
              <img src="~@/assets/images/eprimo-face-4.svg" alt="eprimo-face" />
            </div>
          </div>
          <div class="text-center-mobile">
            <a class="btn" href="https://www.eprimo.de/">eprimo.de besuchen</a>
          </div>
        </div>
        <div class="green-section--right">
          <img src="~@/assets/images/eprimo-face-4.svg" alt="eprimo-face" />
        </div>
      </div>
    </v-container>
  </GreenBlock>
</template>

<script>
import GreenBlock from "@/components/GreenBlock";

export default {
  name: "ThankYouStrompakete",
  components: {
    GreenBlock,
  },
};
</script>

<style lang="scss" scoped>
.page-subtitle-mobile-expired {
    .green-face {
      display: none;
    }
}
.page-subtitle {
  font-weight: 500;
  font-size: 17px;
}
@media only screen and (max-width: 768px) {
  .page-title {
    font-size: 17px;
    margin-top: 0 !important;
  }
  .page-subtitle {
    font-size: 12px;
    font-weight: 400;
    br{display: none;}
  }
  .page-subtitle-mobile-expired {
    position: relative;
    margin-bottom: 86px !important;
    font-size: 16px;
    .green-face {
      display: block;
      position: absolute;
          top: -46px;
    right: 0;
      img {
        width: 163px;
      }
    }
  }
}
.green-section.green-section--big .green-section--right {
  margin: -30px 50px -70px 0;
}
</style>
